.modal-dialog {
  margin-top: 50px;
  @media (min-width: $screen-sm-min) {
  margin-top: 150px;
  width: 480px;
  }
}

.modal-header {
  h4, h5 {
    font-weight: 700;
    padding: 20px 40px;
    text-align: center;
  }
  h5 {
    font-weight: 500;
    padding-top: 0;
  }
  border: none;
  margin-top: 10px;
  padding: 0;
  .modal-icon {
    text-align: center;
    margin-top: -50px;
    background: $c-danger;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 11px;
    margin-left: auto;
    margin-right: auto;
    border: 4px solid #fff;
    &.warning {
      background: $c-action;
    }
    &.info {
      background: $c-button;
    }
    &.success {
      background: $c-success;
    }
    i {
      color: #fff;
      font-size: 48px;
    }
  }
}

.modal-body {
  padding: 10px 40px 5px 40px;
  color: $secondary-text;
  ul {
    text-align: left;
    font-weight: 100;
    font-size: $f-size-s;
    li {
      padding: 6px 0;
      list-style: none;
      &:before {
        content: '\E15B';
        font-family: 'Material Icons';
        float: left;
        margin-top: 1px;
        margin-left: -1.5em;
      }
    }
  }
  .merchandise-heading {
    margin-top: 20px;
  }
  .show-info-label {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 20px;
  }
  .show-status span{
    font-style: normal;
    border: none;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    text-transform: uppercase;
    &.blue {
      background: $c-button;
    }
    &.red {
      background: $c-danger;
    }
    &.green {
      background: $c-success;
    }
    &.yellow {
      background: $c-action;
    }
  }
}

.has-error .modal {
  color: $primary-text;
  .modal-body {
    text-align: left;
  }
  label {
    margin-top: $gap;
    font-size: $f-size-s;
  }
  textarea {
    border-color: $input-border;
    &:focus {
      border: 1px solid $input-focus;
      -webkit-box-shadow: inset 0 0 0 1px $input-focus;
      box-shadow: inset 0 0 0 1px $input-focus;
    }
  }
}

.modal-footer {
  border: none;
  background: url('../../images/border-dash.png') repeat-x left top;
  padding-top: $gap;
  margin-top: $gap *2;
  text-align: center;
}