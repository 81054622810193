.panel-wrapper {
  @include clearfix();
}
.panel-default {
  border: none;
  .panel-heading {
    background: $c-secondary;
    color: #fff;
    border: none;
  }
  .panel-body {
    padding: 0;
    table {
      width: 100%;
      color: $secondary-text;
      td {
        padding: $table-pad;
        font-size: $f-size-s;
        i.material-icons {
          vertical-align: -6px;
          //color: $c-success;
        }
        .bootstrap-select {
          background: none;
          border: none;
          box-shadow: none;
          > button {
            border: none;
            box-shadow: none;
            background: none;
            padding-left: 0;
          }
        }
      }
    }
  }
  .panel-subheading {
    background: darken($c-secondary, 4%);
    color: #fff;
    th {
      padding: 15px 15px 5px 15px;
      font-weight: 100;
      font-size: $f-size-s;
    }
  }
}

.panel-info {
  border: none;
  .panel-heading {
    background: $c-secondary;
    color: #fff;
    border: none;
  }
  .panel-body {
    background: $module-background;
    color: $secondary-text;
    font-size: $f-size-s;
  }
  .show-info .row {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .show-info-label {
    text-transform: uppercase;
    font-weight: 700;
    display: inline;
  }
  .show-info-role {
    display: inline;
    &:not(:last-of-type):after {
      content: ','
    }
  }
  .show-status {
    .row {
      margin: 10px 0;
      .show-status-label {
        font-style: italic;
        font-weight: 700;
        @media (max-width: $screen-sm-max) {
          padding-left: 0;
        }
      }
      span {
        font-style: normal;
        border: none;
        color: #fff;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        text-transform: uppercase;
      }
      .blue {
        background: $c-button;
      }
      .red {
        background: $c-danger;
      }
      .green {
        background: $c-success;
      }
      .yellow {
        background: $c-action;
      }
    }
  }
  .show-stats i {
    vertical-align: -5px;
    line-height: 16px;
  }
  .panel-footer {
    border: none;
    margin-top: $gap;
    @media (max-width: $screen-sm-max) {
      float: none!important;
      text-align: center;
    }
    @media (max-width: $screen-xs-max) {
      a {
        width: 100%;
        + a {
          margin-top: 10px;
        }
      }
    }
  }
}

.panel-list {
  .panel-subheading {
    background: $c-secondary;
    color: #fff;
    border: none;
    th {
      padding: 10px 15px;
      font-weight: 700;
      font-size: $f-size-m;
      &:last-of-type {
        text-align: center;
      }
    }
  }
  .panel-body {
    padding: 0;
    table {
      width: 100%;
      color: $secondary-text;
    }
    td {
      padding: $table-pad;
      font-size: $f-size-s;
      i.material-icons {
        vertical-align: -6px;
        color: $c-success;
      }
      .bootstrap-select {
        background: none;
        border: none;
        box-shadow: none;
        > button {
          border: none;
          box-shadow: none;
          background: none;
          padding-left: 0;
        }
      }
      &:last-of-type {
        //text-align: center;
      }
    }
  }
}

.panel-build {
  @include table-columns(10%, 15%, 45%, 30%);
  tr, td {
    white-space: normal !important;
  }
  .bootstrap-select .dropdown-menu {
    &.open {
      top: 100%;
      right: 0;
      position: absolute;
      width: 100%;
    }
    > li > a {
      padding: 3px 10px;
      font-size: 13px;
    }
  }

}

.panel-actions-above {
  @include clearfix();
  margin: $gap 0;
  @media (max-width: $screen-xs-max) {
    form {
      width: 100%;
      margin-bottom: $gap;
    }
  }

}

.panel-col-3 {
  @include table-columns(20%, 20%, 20%, 20%);
}

.panel-col-4 {
  @include table-columns(10%, 40%, 30%, 20%);
}

.panel-col-5 {
  @include table-columns(20%, 20%, 20%, 20%, 10%);
}

.panel-col-6 {
  @include table-columns(10%, 20%, 20%, 20%, 20%, 10%);
}

.panel-invoice {
  @include tablesaw-stack( 767px );
  @media (max-width: $screen-xs-max) {
    td {
      text-align: left;
    }
  }
  table {
    @include table-columns(30%, 10%, 20%, 40%);
  }
}

.tablesaw-sm {
  @include tablesaw-stack( $screen-xs-max + 1px);
}

.tablesaw-md {
  @include tablesaw-stack( 800px );
}

.tablesaw-lg {
  @include tablesaw-stack( 991px );
}

.tablesaw-sm {
  @media (max-width: $screen-xs-max){
    .tr-stacked {
      display: inline-block!important;
      position: relative;
      width: 100%;
      .checkbox-col {
        text-align: right;
        position: absolute;
        top: -12px;
        right: 0;
        width: auto;
        .tablesaw-cell-content {
          width: auto;
        }
        b {
          display: none!important;
        }
      }
    }
  }
}

.tablesaw-md {
  @media (max-width: 799px){
    .tr-stacked {
      display: inline-block!important;
      position: relative;
      width: 100%;
      .checkbox-col {
        text-align: right;
        position: absolute;
        top: -12px;
        right: 0;
        b {
          display: none!important;
        }
      }
    }
  }
}

.tablesaw-lg {
  @media (max-width: $screen-md-max){
    .tr-stacked {
      display: inline-block!important;
      position: relative;
      width: 100%;
      .checkbox-col {
        text-align: right;
        position: absolute;
        top: -12px;
        right: 0;
        b {
          display: none!important;
        }
      }
    }
  }
}
