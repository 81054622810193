#auth-layout {
  background: url('../../images/login_background_medium.jpg') no-repeat;
  margin-bottom: 120px;
  padding-top: 50px;
  .sub-header {
    text-align: center;
    font-style: italic;
    color: $secondary-text;
    font-size: $f-size-l;
  }
  h1 {
    text-align: center;
    color: $c-button;
    font-size: 60px;
    text-transform: uppercase;
    @media (min-width: $screen-sm-min) {
      text-align: left;
      margin-left: 50px;
    }
  }
  h2 {
    text-align: center;
    margin-top: $gap/2;
    margin-bottom: 0;
  }
  .panel {
    margin-top: 60px;
    background: #dce3db;
    background: rgba(255,255,255,.6);
    padding: $gap;
    .panel-logo {
      text-align: center;
      margin-top: -70px;
    }
    .panel-heading {
      margin-top: 10px;
    }
    .panel-body {
      padding: 15px;
    }
    .form-group {
      position: relative;
      .material-icons {
        position: absolute;
        top: 5px;
        left: 5px;
        color: $c-action;
      }
      input, textarea {
        padding-left: $gap * 1.5;
      }
      .checkbox {
        color: $secondary-text;
        label {
          padding-left: 0;
          span {
            text-transform: inherit;
            vertical-align: 6px;
            padding-left: 2px;
          }
        }
      }
    }
    .btn-login {
      width: 100%;
      display: block;
      background: $c-button;
      color: #fff;
      &:hover {
        background-color: darken($c-button, 10%);
        color: #fff;
      }
      &:active, &:focus, &:active:focus {
        background-color: darken($c-button, 20%);
        color: #fff;
      }
    }
  }
  .btn-link {
    color: #fff;
    font-weight: 600;
    text-shadow: 0 0 3px rgba(0,0,0,.7);
  }
  footer {
    background: #e7eee1;
    background: rgba(255,255,255,.8);
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: 700;
//    height: 120px;
    padding: 10px 0;
    a {
      color: $primary-text;
      &:hover {
        text-decoration: underline;
      }
    }
    .copyright {
      font-weight: 300;
    }
  }
}



