h1 {
  font-family: $font-stack-special;
  margin: 0;
  margin-bottom: 0.7em;
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
}

h2 {
  margin: 0;
  margin-bottom: 0.7em;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  color: $secondary-text;
}

h3 {
  margin: 0;
  margin-bottom: 0.7em;
  padding-top: 10px;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  color: $secondary-text;
}