//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  display: inline-block;
  background: $c-secondary;
  color: #fff;
  padding: 0;
  line-height: 1;
  margin: $line-height-computed 0;
  border-radius: $border-radius-base;
  i {
    vertical-align: -6px;
  }

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      padding: 6px 18px;
      line-height: 1.5;
      text-decoration: none;
      color: #fff;
      background-color: transparent;
      border: none;
      margin-left: 0;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius($border-radius-base);
        padding: 6px 10px;
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($border-radius-base);
        padding: 6px 10px;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      z-index: 2;
      color: #fff;
      background-color: darken($c-secondary, 10%);
      border-color: $pagination-hover-border;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 3;
      color: #fff;
      background-color: darken($c-secondary, 10%);
      border-color: $pagination-active-border;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: darken($c-secondary, 5%);
      background-color: transparent;
      border-color: $pagination-disabled-border;
      cursor: $cursor-disabled;
    }
  }
}