.masthead {
  background: url('../../images/header-bg.jpg') no-repeat;
  background-size: cover;
  padding-top: $gap;
  margin-bottom: 50px;
  header {
    h1 {
      display: inline-block;
      line-height: 1;
      img {
        margin-right: $gap;
      }
      a {
        color: #fff;
        vertical-align: bottom;
        &:hover {
          color: $c-action;
          text-decoration: none;
        }
      }
    }
    .dropdown {
      .dropdown-toggle i {
        font-size: 30px;
        color: $c-secondary;
        background: #fff;
        background: rgba(255,255,255,.75);
        padding: 2px;
        border-radius: 4px;
        box-shadow: 0 0 6px rgba(0,0,0,.175);
      }
      a:hover {
        background: none;
      }
      &.open {
        > a, > a:focus, > a:hover {
          background: none;
        }
        .dropdown-toggle i {
          background: #fff;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        }
      }

    }
  }
}