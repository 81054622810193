@mixin th-width($col, $width) {
  #{$col} {
    width: $width;
  }
}

@mixin table-columns($widths...) {
  table-layout: fixed;
  width: 100%;

  th, td {
    text-overflow: ellipsis;
//    white-space: nowrap;
  }

  $n: 1;
  $var: "th:nth-child(" + $n + ")";
  @each $width in $widths {
    @include th-width($var, $width);
    $n: $n + 1;
    $var: "th:nth-child(" + $n + ")";
  }
}