.alert {
  font-size: $f-size-s;
  display: inline-block;
  background: none;
  border: none;
  margin-top: -15px;
  margin-bottom: 0;
  &.alert-warning .alert-dismissible {
    background: $c-action;
    padding: 15px;
    border-radius: 4px;
  }
  &.alert-danger .alert-dismissible {
    background: $c-danger;
    color: lighten($c-danger, 46%);
    padding: 15px;
    border-radius: 4px;
  }
}

.alert-dismissible .close {
  top: -10px;
  right: -5px;
  opacity: .6;
  text-shadow: none;
}
