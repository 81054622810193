.btn-primary, .btn-primary.dropdown-toggle, .open > .btn-primary.dropdown-toggle {
  background-color: $c-button;
  border: 2px solid transparent;
  &:hover {
    background-color: darken($c-button, 10%);
    border: 2px solid darken($c-button, 10%);
  }
  &:active, &:focus, &:active:focus {
    background-color: darken($c-button, 20%);
    border: 2px solid darken($c-button, 20%);
  }
  &.outlined {
    background: none;
    border: 2px solid $c-button;
    color: $c-button;
    &:hover {
      background-color: $c-button;
      color: #fff;
    }
    &:active, &:focus, &:active:focus {
      background-color: darken($c-button, 10%);
      border-color: darken($c-button, 10%);
      color: #fff;
    }
  }
  &[disabled]:hover {
    background-color: #eee;
    border-color: #eee;
  }
}
a.btn-primary, a.btn-secondary {
  color: #fff;
}
.btn-secondary {
  background-color: $c-action;
  border: none;
  color: #fff;
  &:hover {
    background-color: darken($c-action, 10%);
    border: none;
    color: #fff;
  }
  &:active, &:focus, &:active:focus {
    background-color: darken($c-action, 20%);
    color: #fff;
  }
}

.btn-danger {
  background: $c-danger;
  border: 2px solid $c-danger;
  &:hover {
    background-color: darken($c-danger, 10%);
    border-color: darken($c-danger, 10%);
    color: #fff;
  }
  &:active, &:focus, &:active:focus {
    background-color: darken($c-danger, 20%);
    border-color: darken($c-danger, 20%);
  }
}

.btn-default {
  &:hover, &:focus, &:active:focus {
    background: #fff;
    border: 1px solid $input-focus;
    -webkit-box-shadow: inset 0 0 0 1px $input-focus;
    box-shadow: inset 0 0 0 1px $input-focus;
  }
}
.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
  background: inherit;
}

.btn-sm {
  i.material-icons {
    vertical-align: -6px;
    font-size: 21px;
  }
}

.btn-xs {
  i.material-icons {
    vertical-align: -3px;
    font-size: 14px;
  }
}

.btn-invoice {
  margin: -10px 0 20px;
}

.btn-wide {
  padding-right: 60px;
  padding-left: 60px;
}

.btn-primary ~ .dropdown-menu {
  > li > a:hover, > li > a:focus {
    outline: none;
    background: $c-button;
    color: #fff;
  }
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus {
  background: #eee;
  color: #999;
}

.btn.bulk-btn[disabled], .btn.bulk-btn[disabled]:hover {
  background: $c-button;
  color: #fff;
  opacity: 1;
  border: 2px solid $c-button;
}

.bootstrap-select.btn-group button[title^="Choose"].dropdown-toggle .filter-option {
  color: #999;
}

fieldset[disabled] .dropdown-toggle {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  opacity: 1;
  border-color: #c8c2c2;
}

.invoice-toggle label {
  background: none;
  border: 1px solid #ccc;
  color: #ccc;
  padding: 0;
  margin-left: 4px !important;
  border-radius: 4px !important;
  span {
    padding: 6px 12px;
    display: inline-block;
    &.btn-icon {
      padding: 6px 8px;
      border-right: 1px solid #ccc;
      i {
        color: #ccc !important;
      }
    }
  }
  &:hover, &:focus, &:active:hover {
    border: 1px solid $c-button;
    background: none;
    color: $c-button;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125);
    span {
      &.btn-icon {
        border-right: 1px solid $c-button;
        i {
          color: $c-button !important;
        }
      }
    }
  }
  &.active, &.active:hover {
    background: none;
    border: 1px solid $c-button;
    color: $c-button;
    padding: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125);
    span {
      padding: 6px 12px;
      display: inline-block;
      &.btn-icon {
        padding: 6px 8px;
        border-right: 1px solid $c-button;
        i {
          color: $c-button !important;
        }
      }
    }
  }
}

.btn-invisible {
  background: none;
  padding: 0;
  vertical-align: 0;
  &.petInfo {
    color: $c-button;
    &:hover {
      text-decoration: underline;
    }
  }
}