/* ==========================================================================
Set Up Variables
========================================================================== */

/* Fonts */
$font-stack: 'Open Sans', sans-serif;
$font-stack-special: 'Montserrat', sans-serif;

$f-size-xxs: 10px;
$f-size-xs: 12px;
$f-size-s: 14px;
$f-size-m: 16px;
$f-size-l: 18px;
$f-size-xl: 20px;
$f-size-xxl: 22px;
$f-size-xxxl: 24px;


/* Colors */
$c-success: #01b074;
$c-action: #ecd061;
$c-button: #2841b4;
$c-danger: #af371b;
$c-secondary: #099666;
$primary-text: #373737;
$secondary-text: #645959;



/* Spacing */
$gap: 20px;

/* Line Height */
$b-line-height: 1.5;

/* Modules */
$module-background: #f2f2f2;

/* Forms Inputs */
$input-border: #c8c2c2;
$input-focus: $c-button;

/* Tables */
$table-pad: 10px 15px;