.navbar-nav > li {
  &.settings {
    a {
      text-align: right;
      padding-right: 0;
      padding-bottom: 0;
      &:focus {
        background: none;
      }
    }
    ul {
      margin-top: 10px;
      border-radius: 4px;
      border-color: #645959;
      @include menu-arrow();
      a {
        text-align: left;
        i {
          float: right;
          font-size: $f-size-l;
          margin-right: 20px;
        }
        &:hover {
          color: $c-secondary;
        }
      }
    }
  }
}

nav.navbar {
  background: #c9cdbf;
  background: rgba(255,255,255,.6);
  border: none;
  min-height: 0;
  .navbar-collapse {
    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
      background: #fff;
      z-index: 2;
      position: absolute;
      box-shadow: 0 4px 4px -2px rgba(0,0,0,.25);
      a {
        text-align: left;
        &:hover {
          color: $c-secondary;
        }
        i {
          float: right;
        }
      }
      .divider {
        background: url('../../images/border-dash.png') repeat-x left top;
        padding-top: $gap /2;
        margin-top: $gap /2;
      }
    }
  }
  a {
    color: $primary-text;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: $screen-sm) and (max-width: $screen-md) {
      font-size: $f-size-s;
    }
    &:focus, &:hover {
      background: #fff;
    }
  }
}

.navbar-toggle {
  color: #373737;
  font-size: $f-size-l;
  float: left;
  margin-left: 20px;
  .toggle-menu {
    display: inline-block;
  }
  .icon-bar {
    background: #373737;
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      transform: rotate(45deg);
      transition: all .2s ease-in-out;
      margin-top: -1px;
    }
    &:nth-child(4) {
      transform: rotate(-45deg);
      transition: all .2s ease-in-out;
      margin-top: -2px;
      opacity: 1;
    }
    &:nth-child(5) {
      opacity: 0;
    }
  }
  &.collapsed {
    .icon-bar {
      &:nth-child(2) {
        opacity: 1;
        background: #373737;
        transition: opacity .2s ease-in-out;
        transition-delay: .2s;
      }
      &:nth-child(3) {
        background: #373737;
        transform: rotate(0deg);
        margin-top: 3px;
      }
      &:nth-child(4) {
        transform: rotate(0deg);
        opacity: 0;
        margin-bottom: -1px;
      }
      &:nth-child(5) {
        opacity: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .2s;
        background: #373737;
      }
    }
  }
}
