@mixin menu-arrow() {
  &:after, &:before {
    bottom: 100%;
    right: 6px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
    right: 7px;
  }
  &:before {
    border-color: rgba(100, 89, 89, 0);
    border-bottom-color: #645959;
    border-width: 11px;
    margin-left: -11px;
  }
}