#not-found-page {
  .content {
    margin-top: 50px;
    font-style: italic;
    font-size: 30px;
    h1 {
      font-size: 90px;
      color: $c-button;
      font-style: normal;
      margin-bottom: .5em;
    }
  }
  .divider {
    background: url('../../images/border-dash.png') repeat-x left top;
    height: 2px;
    margin: $gap 0;
    max-width: 50%;
  }
  .btn {
    i {
      vertical-align: -7px;
    }
  }
  .not-found-car {
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../../images/404-car.png) no-repeat;
    height: 437px;
    width: 600px;
    div {
      position: absolute;
      right: 10%;
      bottom: 0;
      font-size: 140px;
      line-height: 1;
      font-weight: 600;
      color: $input-border;
    }
  }
}