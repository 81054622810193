.panel-form {
  background: $module-background;
  color: $secondary-text;
  border: none;
  border-radius: 0;
  padding: $gap * 2;
}

.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}

.form-section-heading {
  font-family: $font-stack-special;
  text-transform: uppercase;
  margin-bottom: $gap;
  &:not(:first-of-type) {
    background: url('../../images/border-dash.png') repeat-x left top;
    padding-top: $gap;
    margin-top: $gap *2;
  }
}

.merchandise-heading {
  background: url('../../images/border-dash.png') repeat-x left bottom;
  text-transform: uppercase;
  padding-bottom: 4px;
  margin-bottom: 10px;
}

.merchandise-wrapper {
  [type="checkbox"] ~ label > span {
    text-transform: inherit;
  }
  input[type="text"] {
    padding: 6px;
  }
}

/* Inputs */
input, textarea {
  &.form-control {
    border-color: $input-border;
    &:focus {
      border: 1px solid $input-focus;
      -webkit-box-shadow: inset 0 0 0 1px $input-focus;
      box-shadow: inset 0 0 0 1px $input-focus;
    }
  }
}

.input-note {
  color: $c-success;
  font-size: $f-size-s;
  margin-top: 4px;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
  margin-top: -3px;
}
.caret {
  border-top: 6px dashed;
  border-top: 6px solid\9;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-bottom: 6px dashed;
  border-bottom: 6px dashed;
  border-bottom: 6px solid\9;
}
.dropdown-menu.open {
  position: relative;
  background: #fff;
  border: 1px solid #645959;
  top: 12px;
  overflow: visible !important;
  padding-right: 4px !important;
  @include scrollbars(.4em, $c-success);
  @include menu-arrow();
  a {
    margin-right: 4px;
  }
  a:focus, .selected a {
    outline: none;
    background: $c-success;
    color: #fff;
  }
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
}

[type="checkbox"] {
  position: absolute;
  left: -9999px;
  ~ label >  span {
    vertical-align: 5px;
    padding-left: 10px;
    text-transform: uppercase;
    .hide {
      display: none;
    }
  }
  + label {
    cursor: pointer;
    font-weight: normal;
    &:before {
      font-family: 'Material Icons';
      display: inline-block;
      box-sizing: border-box;
      padding: 2px 0 0 3px;
      font-size: 24px;
    }
  }
  &:checked + label:before {
    content: "\E834";
  }
  &:not(:checked) + label:before {
    content: "\E835";
  }
}

.form-group [type="checkbox"]:checked + label:before {
  color: $c-button;
}

[type="radio"] {
  position: absolute;
  left: -9999px;
  ~ label >  span {
    vertical-align: 5px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  + label {
    cursor: pointer;
    font-weight: normal;
    &:before {
      font-family: 'Material Icons';
      display: inline-block;
      box-sizing: border-box;
      padding: 2px 0 0 0;
      font-size: 24px;
    }
  }
  &:checked + label:before {
    content: "\E837";
    color: $c-button;
  }
  &:not(:checked) + label:before {
    content: "\E836";
  }
}

input[type="search"] {
  background: $c-secondary;
  color: #fff;
  border: none;
  border-radius: 4px;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $c-secondary inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff;
  }
}

.route-search {
  .form-control {
  height: 36px;
  @media (max-width: $screen-sm-max) {
      width: 100%!important;
    }
  }
}

.search-date {
  border-color: transparent!important;
}

.search-button, .btn-search-secondary {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  border: 0;
  background: none;
  color: #fff;
  font-size: 18px;
}

.search-button {
  span {
    text-indent: -9999px;
    display: inline-block;
  }
}

.btn-search-secondary {
  background: $c-button;
  position: relative;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  @media (max-width: $screen-sm-max) {
    width: 100%!important;
  }
  @media (min-width: $screen-sm-min) {
    span {
      text-indent: -9999px;
      display: inline-block;
    }
  }
  &:hover {
    background-color: darken($c-button, 10%);
  }
  &:active, &:focus, &:active:focus {
    background-color: darken($c-button, 20%);
  }
  i {
    vertical-align: text-top;
  }
}

.searching-for {
  display: block;
  margin-bottom: $gap;
}

.inline-form-button {
  display: inline-block;
}

.m-signature-pad {
  background: #f9f9f9;
  canvas, img {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

.other-pickups .row {
  margin-bottom: $gap/2;
  padding-bottom: $gap/2;
  border-bottom: 1px solid $module-background;
}

.form-control[disabled] {
  color: #999;
}