#pdf-layout {
  margin: 0;
  .ash-form[size="A5"] {
    width: 14.8cm;
    height: 21cm;
    margin: 0;
    padding: 0;
    page-break-after: always;
  }
  .ash-info {
    font-size: $f-size-xs;
  }
  img {
    max-width: 100%;
    max-height: 80px;
    float: right;
  }
  .divider {
    background: url('../../images/border-dash.png') repeat-x left top;
    height: 2px;
    margin: $gap/2 0;
  }
  .order-weight {
    display: table;
    width: 100%;
    margin-bottom: 20px;
    font-size: $f-size-xxs;
  }
  .plain-box {
    font-size: $f-size-xxs;
  }
  .order-weight-row {
    display: table-row;
    div, small {
      display: table-cell;
    }
    small:nth-child(1) {
      padding-left: 22px;
    }
    small:nth-child(2) {
      padding-left: 11px;
    }
    small:nth-child(3) {
      padding-left: 13px;
    }
    small:nth-child(4) {
      padding-left: 7px;
    }
    small:nth-child(5) {
      padding-left: 24px;
    }
    small:nth-child(6) {
      padding-left: 24px;
    }
  }
  [type="checkbox"] {
    + label {
      margin-bottom: 0;
    }
    + label:before {
      font-size: $f-size-l;
    }
    ~ label > span {
      padding-left: 5px;
    }
  }
  .merchandise-wrapper {
    font-size: $f-size-xxs;
    .row .row div:last-of-type {
      text-align: center;
    }
  }
  .merchandise-heading {
    font-size: $f-size-xs;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
    > div {
      padding-right: 4px;
    }

  }
  .form-section-heading:not(:first-of-type) {
    margin-top: 10px;
  }
}
